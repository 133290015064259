
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap:2rem;
}
form p {
    position: relative;
    margin: 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    margin:0;
    text-align: left;
    padding-left: 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    background-color: #F7F7F7;
    border:2px solid #002D6A33;
    height: clamp(50px, 5vw, 68px);
    font-size: clamp(16px, 3vw, 28px);
    border-radius: 7px;
    
}
textarea {
    min-height: 223px;
 
}
label {
    margin:0;
    line-height: clamp(17px, 3.5vw, 30px);
    color: inherit;

    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
    font-size: clamp(16px, 3vw, 23px);
    color: var(--black);
    padding-bottom:1rem;
}


form button.button {
    cursor: pointer;
    display: block;
    
    max-width: 260px;
    width: 100%;
   
    font-size: clamp(16px, 3vw, 20px);
    margin-bottom: 1rem;
    padding:.5rem 3rem; 
    align-self:flex-start;
    @media screen and (max-width:768px) {
        max-width: none;
    }
}