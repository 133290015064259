/*============ splash ============*/
/*  banner */
.t-home{


    .splash-wrapper{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .banners-home{
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: stretch;
        .arrow-wrapper{
            margin:0 4rem;
            position:absolute;
            display: flex;
            padding-bottom:2.5rem;
            justify-content: space-between;
            align-items: flex-end;
            inset:0;
            z-index: 100;
            pointer-events: none;
            @media screen and (max-width:576px) {
                display:none;
            }
            button{
                background-color: transparent; 
                border: none;
                height: 42px;
                width: 42px;

                display: flex;
                justify-content: center;
                align-items: center;
                
                cursor: pointer;
                pointer-events: all;
                img{
                    transition: transform .3s ease-in-out;
                    height: 42px!important;
                    width: 42px;
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

        }
        .banner-stage {
            position:relative;
            display: flex; 
            align-items: stretch;
            width: 100%;
        }
        .banner {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            display:block;
            background-position:center;
            background-size:cover;
            text-decoration: none;
            color: var(--white);
            opacity:0;
            pointer-events: none;
            transition: opacity .3s ease-in-out;
            width: 100%;
            z-index: 0;
        
            &.active{
                position: relative;
                pointer-events: all;
                z-index: 10;
                opacity:1;
                height: 100%;
            }
            .banner-content{
                position: relative;
                height: 100%;
                width: 40%;
                min-width: 600px;
            
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top:2rem;
                padding-bottom:8rem;
                overflow: hidden;
                color: var(--blue);
                &:before{
                    content:"";
                    position: absolute;
                    background-color: rgba(255, 255, 255, .9);
                    border-radius:0 50% 50% 0;
                    top:-20%;
                    right:0;
                    height: 140%;
                    aspect-ratio: 1/1;
                    display: block;
                    z-index: -1;
                }
                .banner-icon{
                    order:-1;
                    margin-bottom: 1rem;
                }
                
                .banner-name{
                    display: none;
                }
                .banner-title, .banner-caption{
                    max-width: 410px;
                    width: 100%; 
                }
                .banner-title{
                    font-size: clamp(16px, 4vw, 55px);
                    line-height: clamp(17px, 4.25vw, 72px);
                    font-weight: 700;
                    margin-bottom: 1rem;
                }
                .banner-caption{
                    
                    font-size: clamp(16px, 3vw, 26px);
                
                    line-height: clamp(17px, 3.5vw, 34px);
                
                    .banner-item-content{
                        display: block;
                        max-width: 360px;
                        margin-right: auto;
                        @media screen and (max-width:576px) {
                            max-width: none;
                            margin: 0;
                        }
                    }
                }
            }
            @media screen and (max-width:768px) {
                display: flex;
                flex-direction: column;
                .banner-content{
                    width: 100%;
                    min-height: 50%;
                    height: fit-content;
                    min-width: 0;
                    padding:1rem;
                    text-align: center;
                    margin-top: auto;
                    &:before{

                        height:100%;
                        top:0;
                        border-radius: 0;
                        width: 100%;
                    }
                    .banner-icon{
                        width: 25vw;
                    }
                    .banner-item-content{
                        margin-right: 0;
                    }
                }
            }
        }
        .banner-navigation {
            display:flex;
            justify-content: center;
            position: absolute;
            padding-bottom: 3rem;
            bottom:0;
            left:0;
            right:0;
            z-index: 100;
            .banner-navigation-body{
                display:flex;
                justify-content: center;
                gap:.75rem;
            }
            .banner-nav-item{
            
                height: 20px;
                width: 20px;
                border: 1px solid var(--white);
                border-radius: 50%;
                cursor: pointer;
                &.active{
                    background-color: var(--white);
                }
                .banner-name, .banner-title, .banner-caption{
                    display: none;
                }
            }
        
        }

    } 
}