.t-practice{
    .page-body{
        margin-bottom: 4rem;
    }
    .item-wrapper{
        display: flex;
        flex-direction: column;
        & > div{
            display: flex;
            gap: 6rem;
            padding:0;
            padding-top: calc(6rem);
            border-top: 1px solid var(--blue);
            margin-top: 6rem;
            
            & > div{
                flex: 1 1 0;
            }
            &:nth-child(odd){
                flex-direction: row-reverse;
            }
            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                margin-bottom: 10rem;
            }
            .related-page-icon img{
                width:100%;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
            .related-page-content{
                .related-page-title{
                    font-weight: 700;
                    font-size: clamp(18px, 3vw, 30px);
                    color: var(--blue);
                    margin-bottom: 0;
                }
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                b{
                    color:var(--blue);
                    display: block;
                }
                a{
                    color:var(--blue);
                }
            }
            @media screen and (max-width:768px) {
                flex-direction: column!important;
                gap:2rem;
                .related-page-content{
                    align-items: center;
                    text-align: center;
                }
            }
        }
    }
}
