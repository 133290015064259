/*============ footer ============*/
footer .footer-bottom {
    position: relative;
    background:var(--blue);
    background-size: cover;
    color:#fff;

    padding:8rem 0;
    justify-content: stretch;
    .contentwrap-wide{
        width: 100%;
        z-index: 2;
    }
    @media screen and (max-width:992px) {
        padding:0;
        padding-top: min(10vw, 4rem);
    }
    .footer_image{
        position: absolute;
        right: 0;
        bottom:0;
        z-index: 1;
        width: min(30vw, 800px);
        @media screen and (max-width:992px) {
            position: relative;
            width: 70vw;
            display: block;
            margin-left: auto;
            margin-top: 2rem;
        }
    }
}
.footer-menu-wrapper{
    display:flex;
    width: 100%;
    gap:min(5vw, 8rem);
    align-items: flex-start;
    margin-bottom: min(10vw, 4rem);
 
    a.logo{
        margin-top: 0;
        margin-left: 0;
        img{
            width: 276px;
        }
    }
    @media screen and (max-width:992px) {
        flex-direction: column;
        align-items: center;
        a.logo{
      
        }
        .footer-menu{
            display: none;
        }
    }
}
.footer-menu > ul{
    display:flex;
    gap:2rem 4rem;
 
    .depth-0 > a > .title{
        font-weight: 600;
        font-size: clamp(16px, 2.5vw, 20px);
    }
    ul{
        margin-top: 1rem;
        display:flex;
        flex-direction: column;  
        font-size: 16px;
        gap:.5rem;
        text-transform: none;
       
    }
    .title:hover{
        // text-decoration: underline;
        border-bottom: 2px solid var(--white);
    }
    @media screen and (max-width:768px) {
        flex-direction: column;
        text-align: center;
    }
}
.footer-contact{
    @media screen and (max-width:992px) {
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width:768px) {
        flex-direction: column;
        align-items: center;
 
    }
}
.footer-contact-grid{
    display:grid;
    grid-template-columns:auto 1fr;
    grid-gap:1.5rem 1.5rem;
    font-size: 16px;
    .icon, img{
        min-width: fit-content;
        width:20px;
        height: 20px!important;;
        object-fit: contain;
        margin-bottom: auto;
    }
    
    .tel, .fax{
        &:hover{
            text-decoration: underline;
        }
    }
    .email{
        &:hover{
            font-weight: bold;
            text-decoration: underline;
        }
    }
}
.socials{
    display: block;
    padding-top: 1.5rem;
    grid-column: 1 / -1;
    .site-links{
        display: flex;
        flex-direction: column;
        gap:1.5rem;
    }
    .site-link{
        display: flex;
        margin:0;
        width: auto;
        gap:2rem;
        .social-link-icon{
            height: 20px!important;
            width: 20px;
            object-fit: contain;
        }
        &:hover{
            filter:none;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}
.footer-top{
    background-color: var(--yellow);
    margin:0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    padding:2rem;
    text-align: center;
    .content{
        width: 1050px;
        background-color: var(--green);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .button-secondary, .button{
            display: inline-flex;
            align-items: center;
            font-size: clamp(16px, 2.5vw, 20px);
            padding:.5rem 2rem;
            width: 300px;
            justify-content: center;
            margin: 1rem 1rem;
            @media screen and (max-width:768px) {
                display: block;
                width: auto;
                min-width: unset;
                margin: 1rem auto;
                white-space: nowrap;
            }
        }
        p{
            display: inline-flex;
            align-items: center;
        }
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding:0 1rem;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--white);
  
    ul{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap:2rem;
        font-weight: 700;
        font-size: clamp(16px, 3vw, 20px);
        padding: 0 min(2vw, 4rem);
        list-style: none;
        a{
            color: var(--blue);
        }
        @media screen and (max-width:500px) {
            justify-content: center;    
            margin-bottom: 2rem;
        }
    }
}
.bykeo a {
    color: var(--black);
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}