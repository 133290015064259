.t-service{
 
    .card-wrapper{
        display: flex;

        margin-left:-10px;
        margin-right: -10px;
        flex-wrap: wrap;
        margin-bottom: 10rem;
        @media screen and (max-width:500px) {
            margin-bottom: 5rem;
        }
        .card{
            position: relative;
            width: 33.33%;
            text-decoration: none;
            color:var(--black);
            transition: all .2s linear, width .3s ease-in-out;
            background-color: var(--white);
            border:10px solid transparent;
            margin-bottom: 1.5rem;
            &.active{
                margin-top: 4rem;
                width: 100%;
                transform: scale(1)!important;
                border-color: transparent;
                .card-img img{
                    aspect-ratio: 16/9!important;
                }
            }
            @media screen and (max-width:900px) {
                width: 50%;
                transform: scale(1)!important;
                &.active .card-img img{
                    aspect-ratio: 4/3!important;
                }
            }
            @media screen and (max-width:500px) {
                width: 100%;
                transform: scale(1)!important;
                &.active .card-img img{
                    aspect-ratio: 4/3!important;
                }
            }
            .card-img{
            
                img{
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                    border-radius: 20px;
                }
            }
            .card-img-default{
                display: block;
                aspect-ratio: 1/1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 8px solid var(--green);
                img{
                    height: auto;
                    width: 50%;
                    margin:auto;
                    object-fit: contain;
                    
        
                }
            }
            
            .card-content{
                margin-top:.75rem;
                a, button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    min-height: 84px;
                    font-size: clamp(16px, 2.5vw, 20px);
                }
            }
            &:hover{
                z-index:10;
            }
        }
    }
    .service-content{
        display: none;
        width: 100%;
        border:10px solid transparent;
        font-size: clamp(16px, 3vw, 20px);
        margin-bottom: 4rem;
        .text-title{
            font-weight: 600;
            margin-bottom: 0;
        }
        &.active{
            display: flex;
            flex-direction: column;
        }
    }
    #card-content-area{
        padding:10px;
    }

}