
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
    gap: 1rem;
    display: flex;
    flex-direction: column;

}


.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 25vw;
    border-radius: 20px;
    overflow: hidden;
    @media screen and (max-width:998px) {
        margin-top: 20px;
        height: 50vh;
    }
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}
.result-wrapper{
    font-size: clamp(18px, 4vw, 30px);
    text-align: center;
    padding: 3rem 1rem;
    color: var(--blue);
}
.t-contact{
    .page-title{
        padding:0;
    }
    .contact-wrapper{
        
        padding: 5rem 0;
        & > .two-col{
            grid-gap:4rem;
        }
        @media screen and (max-width:998px) {
            & > .two-col{
                grid-template-columns: 1fr;
                align-items: center;
                justify-items: center;
            }
            .page-icon-wrapper{
                grid-row: 1; 
            }
        }
       
    }
    .contact-grid{
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap:2rem 3rem;
        justify-items: center;
        a, .item{
            width:100%;
        }
        p{
            margin:0;
        }
        .icon{
            width:24px;
            height: 24px;
            object-fit: contain;
        }
    }
    .page-icon-wrapper{
        display: flex;
    }
    .page-icon{
        display: block;
        margin:auto;
        img{
            width: 100%;
            border-radius: 20px;
        }
    }
}