html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}



fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:clamp(16px, 2.5vw, 20px);
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border:none;
}
a.logo {
    display: flex;
    margin: 1rem;
    img{
        width: 220px;
        height:auto;
        margin-right: 1rem;
    }
    h2{
        p{
            margin:0;
        }
        padding: 0 1rem;
        border-left: 1px solid #333333;
 
    }
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
.hotdoc-wrapper{
    margin-top: 4rem;
}
@import "utils";
@import "vars";
@import "menus";
@import "forms";
@import "site_links";
@import "header";
@import "mobile_menu";
@import "footer";
@import "index";
@import "page";
@import "contact";
@import "block";
@import "service";
@import "faq";
@import "practice_information";
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
@import "helpers";
@import "print";