

.phone-icon{
    content: url('../assets/phone.svg');
    height: 24px;
    width: 24px;
}
.fax-icon{
    content: url('../assets/fax.svg');
    height: 24px;
    width: 24px;
}
.time-icon{
    content: url('../assets/time.svg');

}
.email-icon{
    content: url('../assets/email.svg');
}
.abn-icon{
    color: var(--yellow);
    font-weight: 700;
    font-size: clamp(16px, 3vw, 20px);
    display: inline-block;
    height: auto;
}
.location-icon{
    content:url("../assets/location.svg")
}
.row{
    display:flex;
}
.col{
    display:flex;
    flex-direction: column;
}
.contentwrap-wide{
    max-width: 1400px;
    position: relative;
    margin:0 auto;
    padding:20px;
}
.cta-wrapper{
    ul{
        display: flex;
        flex-direction: row;
        margin:0;
        padding: 0;
        list-style: none;
        li{
            flex: 1 1 0;
           a{
                position: relative;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .title{
                    position: absolute;
                    font-weight: 700;
                    color:var(--white);
                    font-size: clamp(16px, 4vw, 55px);
                    z-index: 10;
                    
                }
                img{
                    width: 100%;
                    height: 100%!important;
                    object-fit: cover;
                    transition: transform .3s ease-in-out;
                }
                &::after{
                    content:"";
                    position: absolute;
                    inset:0;
                    background-color: #28292B;
                    opacity: .35;
                }
                &::before{
                    content:"";
                    position: absolute;
                    inset:0;
                    background-color: var(--blue);
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                    &::before{
                        opacity:.35;
                    }
                }
           } 
        }
    }
    @media screen and (max-width:768px) {
        ul{
            flex-direction: column;
           
            li{
                height: 40vw;
                flex:initial;
            }
        }
    }
}
.pager{
    margin:4rem 0;
    padding:0;
    display: flex;
    gap:min(4vw, 1.5rem);
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    li{
        background-color: var(--white);
        border: 2px solid var(--blue);
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        border-radius: 7px;
        color:var(--blue);
 
        a, span{
            display: block;
            text-decoration: none;
            color:inherit;
            padding:1rem;
        }
        &:hover{
            background-color: var(--blue);
            color:var(--white)
        }
        &.active{
            background-color: var(--blue);
            cursor: pointer;
            pointer-events: none;
            color:var(--white);
        }
        &.disabled{
            cursor:not-allowed;
            color:#888;
            border-color: #888;
            pointer-events: none;
        }
    }
}