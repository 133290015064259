.t-faq{
    .page-body{
        margin-bottom: 4rem;
    }
    .faq-wrapper{
        padding-top: 0;
        margin-bottom: 10rem;
        .faq-item{
            margin-bottom: 2rem;
            .expand-icon{
                margin-left: auto;
                margin-right: 2rem;
                position: relative;
                min-width: 30px;
                padding:0 6px;
                color: var(--white);
                font-weight: 800;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before, &::after{
                    position: absolute;
                    content:"|";
                    font-size: 30px;
                    line-height: 30px;
                    display: inline-block;
                }
                &::before{
                    transform: rotate(90deg);
                    transition: transform .3s ease-in-out;
                }
         
                &::after{
                    transform: rotate(90deg);
                }
            }
            .faq-title{
                margin:0;
                padding:2rem 0;
                padding-left: 2rem;
                background-color: var(--blue);
                color: var(--white);
                display: flex;
                width: 100%;
                font-size: clamp(17px, 3.4vw, 30px);
                font-weight: 700;
                transition: background-color .3s ease-in-out;
                border-radius:20px;
                border:3px solid var(--blue);
            }
            .faq-content{
                transition: max-height .3s ease-in-out;
                overflow: hidden;
                .wrapper{
                    padding-top:2rem;
                    padding-right: 2rem;
                }
            }
            &.fold{
                .faq-title{
                    background-color: var(--white);
                    color:var(--blue);
                    .expand-icon{
                        color: var(--pink);
                        &::before{
                            transform: rotate(0deg)translate(-2px,-1px);
                        }
                    }
                }
                .faq-content{
                    max-height: 0px!important;
                }
            }
            @media screen and  (max-width:500px){
                .expand-icon{
                    display: none;
                };
                .faq-title{
                    padding:1rem;
                }
                .faq-content{
                    .wrapper{
                        padding:1rem;
                    }
                }
            }
        }
    }
}