.after-content{
    padding:8rem 0;
}


.page-list-wrapper{
    display: flex;
    flex-direction: column;
    padding:0 20px;
    & > div{
        display: flex;
        gap: 6rem;
        padding:0;
        padding-bottom: calc(6rem);
        border-bottom: 1px solid var(--blue);
        margin-bottom: 6rem;
        
        & > div{
            flex: 1 1 0;
        }
        &:nth-child(odd){
            flex-direction: row-reverse;
        }
        &:last-child{
            border-bottom: none;
            margin-bottom: 0;
        }
        .related-page-icon img{
            border-radius: 20px;
            width:100%;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
        .related-page-content{
            .related-page-title{
                font-weight: 700;
                font-size: clamp(18px, 3vw, 30px);
                color: var(--blue);
                margin-bottom: 0;
            }
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap:1rem;
            b{
                color:var(--blue);
                display: block;
            }
        }
        @media screen and (max-width:768px) {
            flex-direction: column!important;
            gap:2rem;
            .related-page-content{
                align-items: center;
                text-align: center;
            }
        }
    }
}
